// Import Library
import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import { MatxLoading } from 'app/components'
import http from "../../comunication/http-common";

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken)
        return false

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Math.floor(Date.now() / 1000);
    return decodedToken.expire > currentTime
}

const mapUserProperties = (user, geolocation = null) => ({
    email: user?.email,
    names: user?.names,
    surnames: user?.surnames,
    internationalDiallingCode: user?.internationalDiallingCode,
    phone: user?.phone,
    expire: user?.expire,
    token: user?.token,
    role: 'SA',
    city: geolocation?.city || user?.city,
    country: geolocation?.country || user?.country,
    country_code: geolocation?.country_code || user?.country_code,
    country_code3: geolocation?.country_code3 || user?.country_code3,
    ip: geolocation?.ip || user?.ip,
    latitude: geolocation?.latitude || user?.latitude,
    longitude: geolocation?.longitude || user?.longitude,
    region: geolocation?.region || user?.region,
    timezone: geolocation?.timezone || user?.timezone,
})

const setSession = (user) => {
    if (user) {
        const header = { alg: 'RS0A', typ: 'JWT', };
        const encodedHeader = btoa(JSON.stringify(header));
        const encodedPayload = btoa(JSON.stringify(user));
        const clientToken = `${encodedHeader}.${encodedPayload}.`;
        localStorage.setItem('accessToken', clientToken);
    } else {
        localStorage.removeItem('accessToken')
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    assign: () => { },
    logout: () => { },
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (login) => {
        try {
            const url = process.env.REACT_APP_URL_GATEWAY + "/identity/signin";
            const response = await http.post(url, login);
            const responseGeolocation = await http.get(`${process.env.REACT_APP_GEOLOCATION_URL}`);
            const user = mapUserProperties(response.data, responseGeolocation.data);
            user.email = login.email;
            setSession(user)
            dispatch({ type: 'LOGIN', payload: { user } })
        } catch (error) {
            throw new Error(JSON.stringify({ "code": error.response?.data?.type || error.name || "UnknownError", "message": error.response?.data?.title || error.message || "Unknown error occurred" }));
        }
    }

    const assign = (data, type) => {
        const accessToken = window.localStorage.getItem('accessToken')
        if (accessToken && isValidToken(accessToken)) {
            const decodedToken = jwtDecode(accessToken);
            const user = mapUserProperties(decodedToken, null);
            if (type === 'token') {
                user.token = data
            }
            else if (type === 'alias') {
                user.names = data
            }
            setSession(user)
            dispatch({ type: 'INIT', payload: { isAuthenticated: true, user, }, })
        } else {
            setSession(null)
            dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null, }, })
        }
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                if (accessToken && isValidToken(accessToken)) {
                    const decodedToken = jwtDecode(accessToken);
                    const user = mapUserProperties(decodedToken, null);
                    setSession(user)
                    dispatch({ type: 'INIT', payload: { isAuthenticated: true, user, }, })
                } else {
                    setSession(null)
                    dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null, }, })
                }
            } catch (err) {
                setSession(null)
                dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null, }, })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                assign,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext