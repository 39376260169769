import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));
const OtpRegister = Loadable(lazy(() => import('./OtpRegister')));

const sessionRoutes = [
    {
        path: '/session/signup',
        element: <JwtRegister />
    },
    {
        path: '/session/otp',
        element: <OtpRegister />
    },
    {
        path: '/session/signin',
        element: <JwtLogin />
    },
    {
        path: '/session/forgot-password',
        element: <ForgotPassword />
    },
    {
        path: '/session/404',
        element: <NotFound />
    },
];

export default sessionRoutes;