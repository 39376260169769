import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppCredits = Loadable(lazy(() => import('./credits/AppCredits')));
const AppAdvertise = Loadable(lazy(() => import('./advertise/AppAdvertise')));
const AppMovements = Loadable(lazy(() => import('./movements/AppMovements')));
const AppPaymentSuccess = Loadable(lazy(() => import('./success/AppPaymentSuccess')));

const transactionRoutes = [
    {
        path: '/transaction/credits',
        element: <AppCredits />,
    },
    {
        path: '/transaction/advertise',
        element: <AppAdvertise />,
    },
    {
        path: '/transaction/movements',
        element: <AppMovements />,
    },
    {
        path: '/transaction/paymentsuccess',
        element: <AppPaymentSuccess />
    },
];

export default transactionRoutes;