export const navigations = [

    { name: 'Mi Resumen', path: '/biography/default', icon: 'dashboard' },
    {
        name: 'Mi Perfil',
        icon: 'security',
        children: [
            { name: 'Perfil', path: '/administration/profile', iconText: 'F' },
        ],
    },
    {
        name: 'Mis Transacciones',
        icon: 'money',
        children: [
            { name: 'Comprar Créditos', path: '/transaction/credits', iconText: 'A' },
            { name: 'Publicar Anuncios', path: '/transaction/advertise', iconText: 'A' },
            { name: 'Movimientos', path: '/transaction/movements', iconText: 'A' },
        ],
    }
];