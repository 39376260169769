import config from "../config";

// Color Themes
const themes = {
    jade: {
        // primary color
        primaryColor: "#f44c7c",
        secondaryColor: '#1976d2',

        // text color
        primaryText: "#010203",
        secondaryText: "#5d5d5d",

        // background
        background: "#fff",
    }
};

const theme = themes[config.theme];

export default theme;