import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const error = require('../../../assets/png/404.png').default;

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
    maxWidth: 320,
    flexDirection: 'column',
    justifyContent: 'center',
}));

const IMG = styled('img')(() => ({
    width: '100%',
    marginBottom: '32px',
}));

const NotFoundRoot = styled(FlexBox)(({ height }) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: height || '100vh !important', // Usa la prop 'height' si está definida    
}));

const NotFound = ({ height }) => {
    const navigate = useNavigate();

    return (
        <NotFoundRoot height={height}>
            <JustifyBox>
                <IMG src={error} alt="" />
                <Button color="primary" variant="contained" sx={{ textTransform: 'capitalize' }} onClick={() => navigate(-1)} >
                    Regresa
                </Button>
            </JustifyBox>
        </NotFoundRoot>
    );
};

export default NotFound;